import { useState, useEffect, useRef, useContext } from 'react'

import store from 'redux/store'
import { getLoggedInUser } from 'redux/selectors'
import { ThemeContext } from 'ThemeContext';
import * as api from './paymentapi'
import BraintreeWebHostedFields from 'braintree-web/hosted-fields'
import { history } from 'services/history.js'
import { showLoading } from 'services/util'

import PaymentMethodsSingleCard from './PaymentMethodsSingleCard'
import InputWarning from './InputWarning'

import buttonStyle from '../sass/buttons.module.scss'
import css from '../payment/sass/update.module.scss'
import formStyle from '../sass/forms.module.scss'

const ccTypeCodes = {
  'Visa': 'VI',
  'MasterCard': 'MC',
  'Discover Card': 'DS',
  'American Express': 'AX',
}

function UpdatePaymentForm(props) {
  const theme = useContext(ThemeContext)
  const [hostedFields, setHostedFields] = useState();
  const [validCardholderName, setValidCardholderName] = useState(true);
  const [validCardNumber, setValidCardNumber] = useState(true);
  const [validCardExpDate, setValidCardExpDate] = useState(true);
  const [validCardSecurityCode, setValidCardSecurityCode] = useState(true);
  const [validCardZipCode, setValidCardZipCode] = useState(true);
  const [genericErrorText, setGenericErrorText] = useState(props.loadError);
  const [testEmailAddress, setTestEmailAddress] = useState('');
  
  const loggedInUser = getLoggedInUser(store.getState())

  useEffect(() => {
    setGenericErrorText(props.loadError)
  }, [props.loadError]);

  const hostedFieldsRef = useRef(hostedFields)
  useEffect(() => {
    hostedFieldsRef.current = hostedFields;
  }, [hostedFields]);

  useEffect(() => {
    async function setup() {
      try {
        const options = {
          client: props.clientInstance,
          styles: {
            ':focus': {
              'color': 'black'
            },
          },
          fields: {
            cardholderName: {
              selector: '#cc-name',
              placeholder: 'Name as it appears on your card'
            },
            number: {
              container: '#card-number',
              supportedCardBrands: {
                'visa': true, // True allows the card types and showing up as valid
                'mastercard': true,
                'american-express': true,
                'discover': true,
                'diners-club': false, // False does not show as allowed card type
                'jcb': false,
                'maestro': false,
                'elo': false,
                'mir': false,
                'hiper': false,
                'hipercard': false
              },
              selector: '#card-number',
              placeholder: '4111 1111 1111 1111'
            },
            cvv: {
              selector: '#cvv',
              placeholder: '123'
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: 'MM/YYYY'
            },
            postalCode: {
              selector: '#postal-code',
              placeholder: '11111'
            }
          }
        }

        const hostedFieldsInstance = await BraintreeWebHostedFields.create(options)

        console.log(hostedFieldsInstance)
        setHostedFields(hostedFieldsInstance)// Use the Hosted Fields instance here to tokenize a card
      }
      catch(err) {
        console.log(err)
        // Handle error in component creation
      };
    }
    setup()

    // prevents duplicate iFrame error on resize or other state change (like dev)
    return () => { if (hostedFieldsRef.current) hostedFieldsRef.current.teardown() }

    // had to change it to props.clientInstance because when the loaded card changed, it caused a dual teardown, erroring out
  }, [props.clientInstance])
  
  async function handleSubmit(e) {
    e.preventDefault();
    
    // const processorTokenToBeUpdated = props.card.processorTokenId;
    showLoading(true)
    let nonceFromClient;

    if (process.env.REACT_APP_AWS_ENVIRONMENT === 'prod' && (loggedInUser.cognitoUser.attributes.email || "").indexOf('@test.com') !== -1) {
      setGenericErrorText('Cannot save test user in production.')
      showLoading(false)
      return;
    }
    
    //try catch for the submission of hosted fields
    try {      
      const tokenResponse = await hostedFields.tokenize()
      nonceFromClient = tokenResponse.nonce;
      
    } catch (formError) {
      //show Braintree errors in the console
      console.log(formError);
    
      showLoading(false);

      //show errors for invalid fields
      if (formError.code === 'HOSTED_FIELDS_FIELDS_INVALID') {
          const invalidKeys = formError.details.invalidFieldKeys
          if (invalidKeys.includes("cardholderName")){
            setValidCardholderName(false);
          } else {
            setValidCardholderName(true);
          } 
          if (invalidKeys.includes("number")){
            setValidCardNumber(false);
          } else {
            setValidCardNumber(true);
          }
          if (invalidKeys.includes("cvv")){
            setValidCardSecurityCode(false);
          } else {
            setValidCardSecurityCode(true);
          }
          if (invalidKeys.includes("expirationDate")){
            setValidCardExpDate(false);
          } else {
            setValidCardExpDate(true);
          }
          if (invalidKeys.includes("postalCode")){
            setValidCardZipCode(false);
          } else {
            setValidCardZipCode(true);
          }
      }
      // if submitted with all fields empty
      if (formError.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
        setValidCardholderName(false);
        setValidCardNumber(false);
        setValidCardSecurityCode(false);
        setValidCardExpDate(false);
        setValidCardZipCode(false);
      }  
      // generic error for network/client issues
      if (formError.code.startsWith('CLIENT_')) {
        setGenericErrorText('Network error, please try again.')
      }

      return;
    }

    //try catch for the api call to update the payment method
    try {
      // don't even send primary email in non-prod, so there's no chance of sending a success email to a real user's email during testing (primary is used as a backup if the user is a phone user and has no cognitoEmail)
      const primaryEmail = (process.env.REACT_APP_AWS_ENVIRONMENT === 'prod') ? loggedInUser.crmUser.profile.primaryEmail : "";
      let updatedCardInfo = await api.savePaymentMethod(props.card.revenueLookupIdArray, nonceFromClient, props.associatedGifts.map(gift => { return {revenueType: gift.revenueType} }), primaryEmail, testEmailAddress);
      showLoading(false)
      
      if (updatedCardInfo.error) {
        if (updatedCardInfo.error.key === 'ERROR_SAVING_PAYMENT_METHOD_TO_CRM') { // to test, send a bad revenue ID
          setGenericErrorText('There was an error applying your updated credit card to the gifts specified. Please contact Donor Relations support at ' + theme.supportPhone + '.')
        }
        else { // to test, send a bad nonce
          setGenericErrorText('Oops something went wrong. Please try again or contact Donor Relations support at ' + theme.supportPhone + '.')
        }
        console.error(updatedCardInfo.error);
      }
      else {
        //deconstruct key/values from updatedCardInfo from back end, create the new card's unique identifier
        const {ccExpiresOn, ccLastFour, ccName, ccType, updatedRevenueIds} = updatedCardInfo;
        const newUniqueIdentifier = ccExpiresOn + ccType + ccName + ccLastFour;
  
        //send the new card's info into the parent component to update loggedInUser while updating UI before rerouting
        props.updateLoggedInUser({
          ccExpiresOn,
          ccLastFour,
          ccName,
          ccType,
          ccTypeCode: ccTypeCodes[updatedCardInfo.ccType],
          "uniqueIdentifier": newUniqueIdentifier,
          revenueLookupIdArray: updatedRevenueIds,
        });   
      } 
    } catch(customerError) {
      showLoading(false)
      setGenericErrorText('Oops something went wrong. Please try again or contact Donor Relations support at ' + theme.supportPhone + '.')
      console.error(customerError);
    }
  }

  return (
    <form id="cardForm" runat="server" method="post" className={`panel-body ${css.body}`}>
      <section className={css['form-section']}>      
        <h2><label id="lblAmount" runat="server" ></label></h2>
        <input id="amount" runat="server" name="amount" type="hidden" min="1" placeholder="Amount" value="" readOnly={true} />
        <input type="hidden" id="nonce" name="nonce" />
        <div className={css['section-body']}>
          <div className={css['section-body__left']}>
            <PaymentMethodsSingleCard card={props.card} cardValid={true} paymentUpdateForm={true}/>
          </div>
          <div className={css['section-body__right']}>
            <div className={css['section-body__right-header']}>
              <h4>New Credit Card Info</h4>
            </div>
            <div className={css['form-grid']}>
              {/* top row */}
              {/* named after the grid area they belong to */}
              <div className={css['form-grid__entirefield-ccname']}>
                <label htmlFor="cc-name" className={`hosted-fields--label ${css['form-label']}`}>Cardholder Name</label>
                <div id="cc-name" className={`hosted-field ${css['payment-form-input']} ${formStyle.input} ${validCardholderName ? '' : css['braintree-warnings__input'] }`}>
                  <InputWarning show={validCardholderName}></InputWarning>
                </div>
                <p className={`${validCardholderName ? css['hidden-warning'] : css['braintree-warnings__text'] }`}>Please enter a cardholder name</p>
              </div>
              <div className={css['form-grid__entirefield-number']}>
                <label htmlFor="card-number" className={`hosted-fields--label ${css['form-label']}`}>Card Number</label>
                <div id="card-number" className={`hosted-field ${css['payment-form-input']} ${formStyle.input} ${validCardNumber ? '' : css['braintree-warnings__input'] }`}>
                  <InputWarning show={validCardNumber}></InputWarning>
                </div>
                <p className={`${validCardNumber ? css['hidden-warning'] : css['braintree-warnings__text'] }`}>Please enter a valid card number</p>
              </div>
              {/* bottom row */}
              <div className={css['form-grid__entirefield-date']}>
                <label htmlFor="expiration-date" className={`hosted-fields--label ${css['form-label']}`}>Expiration Date</label>
                <div id="expiration-date" className={`hosted-field ${css['payment-form-input']} ${formStyle.input} ${validCardExpDate ? '' : css['braintree-warnings__input'] }`}>
                  <InputWarning show={validCardExpDate}></InputWarning>
                </div>
                <p className={`${validCardExpDate ? css['hidden-warning'] : css['braintree-warnings__text'] }`}>Please enter a valid expiration date</p>
              </div>
              <div className={css['form-grid__entirefield-cvv']}>
                <label htmlFor="cvv" id="lblcvv" runat="server" className={`hosted-fields--label ${css['form-label']}`}>Security Code</label>
                <div id="cvv" runat="server" className={`hosted-field ${css['payment-form-input']} ${formStyle.input} ${validCardSecurityCode ? '' : css['braintree-warnings__input'] }`}>
                  <InputWarning show={validCardSecurityCode}></InputWarning>
                </div>
                <p className={`${validCardSecurityCode ? css['hidden-warning'] : css['braintree-warnings__text'] }`}>Please enter a valid security code</p>
              </div>
              <div className={css['form-grid__entirefield-postal']}>
                <label htmlFor="postal-code" id="lblpostalcode" runat="server" className={`hosted-fields--label ${css['form-label']}`}>Card Zip/Postal Code</label>
                <div id="postal-code"  runat="server" className={`hosted-field ${css['payment-form-input']} ${formStyle.input} ${validCardZipCode ? '' : css['braintree-warnings__input'] }`}>
                  <InputWarning show={validCardZipCode}></InputWarning>
                </div>
                <p className={`${validCardZipCode ? css['hidden-warning'] : css['braintree-warnings__text'] }`}>Please enter a valid Zip/Postal Code</p>
              </div>
            </div>
          </div>
        </div>
        <p className={css['braintree-warnings__generic']}>{genericErrorText}</p>
        <div className={css["form-button-container"]}>
          <button
            onClick ={(e) => {history.push({ pathname: '/paymentmethods' })}}
            className={`${buttonStyle.button} ${buttonStyle.StrokeBlue} ${buttonStyle.WideMobileButton} ${css['form-button-container__back']}`}>
              Cancel and Go Back
          </button>
          <input onClick={handleSubmit}
            type="submit"
            className={`${buttonStyle.button} ${buttonStyle.FilledBlue} ${buttonStyle.WideMobileButton} ${css['form-button-container__submit']}`}
            value="Save New Credit Card"
            id="btnSubmit" />
        </div>

        {process.env.REACT_APP_AWS_ENVIRONMENT !== "prod" && <>
            <div>Test email (use your own email here to preview the save success email - this field won't show up in production)</div>
            <input
              type="text"
              size="30"
              id="testSendEmail"
              autoComplete="email"
              onChange={(e) => setTestEmailAddress(e.target.value)}
              value={testEmailAddress} />
            <br />&nbsp;<br />
          </>}

      </section>
    </form>
  )
}

export default UpdatePaymentForm